import { TrendingUp, Settings, Database, Bot, Code, Bell, Briefcase } from 'lucide-react';
import { ReactNode } from 'react';

interface TradingComponent {
  type: string;
  label: string;
  icon: ReactNode;
}

export const tradingComponents: TradingComponent[] = [
  {
    type: 'datasource',
    label: 'Data Source',
    icon: <Database className="h-4 w-4" />,
  },
  {
    type: 'indicator',
    label: 'Technical Indicator',
    icon: <TrendingUp className="h-4 w-4" />,
  },
  {
    type: 'llm-agent',
    label: 'LLM Agent',
    icon: <Bot className="h-4 w-4" />,
  },
  {
    type: 'condition',
    label: 'Condition',
    icon: <Settings className="h-4 w-4" />,
  },
  {
    type: 'repl',
    label: 'Mini Program',
    icon: <Code className="h-4 w-4" />,
  },
  {
    type: 'notification',
    label: 'Notification',
    icon: <Bell className="h-4 w-4" />,
  },
  {
    type: 'broker',
    label: 'Broker',
    icon: <Briefcase className="h-4 w-4" />,
  },
];
