import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Button } from '../ui/button';
import { Bell, Search, Trash2 } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '../ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../ui/popover';

const notificationTypes = [
  { id: 'webhook', name: 'Webhook', description: 'Send HTTP POST request' },
  { id: 'email', name: 'Email', description: 'Send email notification' },
  { id: 'telegram', name: 'Telegram', description: 'Send Telegram message' },
  { id: 'phone', name: 'Phone', description: 'Send SMS notification' }
];

export function NotificationNode({ data, id }: { 
  data: { 
    type: string; 
    notificationType?: string; 
    url?: string; 
    email?: string; 
    content?: string; 
    username?: string;
    phoneNumber?: string;
  }; 
  id: string 
}) {
  const [open, setOpen] = useState(false);
  const [notificationType, setNotificationType] = useState(data.notificationType || '');
  const [url, setUrl] = useState(data.url || '');
  const [email, setEmail] = useState(data.email || '');
  const [content, setContent] = useState(data.content || '');
  const [username, setUsername] = useState(data.username || '');
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber || '');
  const { setNodes } = useReactFlow();

  const handleSelect = (id: string) => {
    setNotificationType(id);
    data.notificationType = id;
    setOpen(false);
  };

  const handleUrlChange = (value: string) => {
    setUrl(value);
    data.url = value;
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    data.email = value;
  };

  const handleContentChange = (value: string) => {
    setContent(value);
    data.content = value;
  };

  const handleUsernameChange = (value: string) => {
    setUsername(value);
    data.username = value;
  };

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
    data.phoneNumber = value;
  };

  const selectedType = notificationTypes.find(t => t.id === notificationType);

  return (
    <Card className="min-w-[300px] p-4 relative">
      <Handle type="target" position={Position.Top} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mb-2">
          <Bell className="h-4 w-4" />
          <span>Notification</span>
        </div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" role="combobox" className="justify-between">
              {selectedType ? selectedType.name : 'Select notification type...'}
              <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] p-0">
            <Command>
              <CommandInput placeholder="Search notification types..." />
              <CommandList>
                <CommandEmpty>No type found.</CommandEmpty>
                <CommandGroup>
                  {notificationTypes.map((type) => (
                    <CommandItem key={type.id} onSelect={() => handleSelect(type.id)}>
                      <span>{type.name}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {selectedType && (
          <p className="text-sm text-muted-foreground mb-2">
            {selectedType.description}
          </p>
        )}
        {notificationType === 'webhook' && (
          <Input
            value={url}
            onChange={(e) => handleUrlChange(e.target.value)}
            placeholder="Enter webhook URL..."
            className="mt-2"
          />
        )}
        {notificationType === 'email' && (
          <>
            <Input
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              placeholder="Enter email address..."
              type="email"
              className="mt-2"
            />
            <Textarea
              value={content}
              onChange={(e) => handleContentChange(e.target.value)}
              placeholder="Enter email content..."
              className="mt-2"
            />
          </>
        )}
        {notificationType === 'telegram' && (
          <>
            <Input
              value={username}
              onChange={(e) => handleUsernameChange(e.target.value)}
              placeholder="Enter Telegram username..."
              className="mt-2"
            />
            <Textarea
              value={content}
              onChange={(e) => handleContentChange(e.target.value)}
              placeholder="Enter message content..."
              className="mt-2"
            />
          </>
        )}
        {notificationType === 'phone' && (
          <>
            <Input
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              placeholder="Enter phone number..."
              type="tel"
              className="mt-2"
            />
            <Textarea
              value={content}
              onChange={(e) => handleContentChange(e.target.value)}
              placeholder="Enter SMS content..."
              className="mt-2"
            />
          </>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
}
