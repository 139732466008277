import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Textarea } from '../ui/textarea';
import { Settings, Trash2 } from 'lucide-react';
import { Button } from '../ui/button';

export function ConditionNode({ data, id }: { data: { type: string; statements?: string }; id: string }) {
  const [statements, setStatements] = useState(data.statements || '');
  const { setNodes } = useReactFlow();

  const handleStatementsChange = (value: string) => {
    setStatements(value);
    data.statements = value;
  };

  return (
    <Card className="min-w-[250px] p-4 relative">
      <Handle type="target" position={Position.Top} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mb-2">
          <Settings className="h-4 w-4" />
          <span>Condition</span>
        </div>
        <Textarea
          value={statements}
          onChange={(e) => handleStatementsChange(e.target.value)}
          placeholder="Enter condition statements..."
          className="min-h-[100px]"
        />
      </div>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
}
