import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Briefcase, Search, Trash2 } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

const brokerTypes = [
  { id: 'paper', name: 'Paper Trading', description: 'Simulate trading without real money' },
  { id: 'alpaca', name: 'Alpaca', description: 'Live trading with Alpaca Markets' },
];

export function BrokerNode({ data, id }: { data: { type: string; broker?: string; action?: 'buy' | 'sell'; amount?: number }; id: string }) {
  const [open, setOpen] = useState(false);
  const [broker, setBroker] = useState(data.broker || '');
  const [action, setAction] = useState(data.action || 'buy');
  const [amount, setAmount] = useState(data.amount || '');
  const { setNodes } = useReactFlow();

  const handleSelect = (id: string) => {
    setBroker(id);
    data.broker = id;
    setOpen(false);
  };

  const handleActionChange = (value: 'buy' | 'sell') => {
    setAction(value);
    data.action = value;
  };

  const handleAmountChange = (value: string) => {
    setAmount(value);
    data.amount = parseFloat(value) || 0;
  };

  const selectedBroker = brokerTypes.find(b => b.id === broker);

  return (
    <Card className="min-w-[300px] p-4 relative">
      <Handle type="target" position={Position.Top} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mb-2">
          <Briefcase className="h-4 w-4" />
          <span>Broker</span>
        </div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" role="combobox" className="justify-between">
              {selectedBroker ? selectedBroker.name : 'Select broker...'}
              <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] p-0">
            <Command>
              <CommandInput placeholder="Search brokers..." />
              <CommandList>
                <CommandEmpty>No broker found.</CommandEmpty>
                <CommandGroup>
                  {brokerTypes.map((type) => (
                    <CommandItem key={type.id} onSelect={() => handleSelect(type.id)}>
                      <span>{type.name}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {selectedBroker && (
          <p className="text-sm text-muted-foreground mb-2">
            {selectedBroker.description}
          </p>
        )}
        <Select value={action} onValueChange={handleActionChange}>
          <SelectTrigger>
            <SelectValue placeholder="Select action" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="buy">Buy</SelectItem>
            <SelectItem value="sell">Sell</SelectItem>
          </SelectContent>
        </Select>
        <Input
          type="number"
          value={amount}
          onChange={(e) => handleAmountChange(e.target.value)}
          placeholder="Enter amount..."
          className="mt-2"
        />
      </div>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
}
