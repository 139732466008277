import { useEffect, useCallback, useRef, useState } from 'react';
import { useToast } from "@/hooks/use-toast";

export function useWebSocket() {
  const ws = useRef<WebSocket | null>(null);
  const { toast } = useToast();
  const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const maxReconnectAttempts = 5;
  const reconnectAttemptsRef = useRef(0);
  const [isConnected, setIsConnected] = useState(false);

  const connect = useCallback(() => {
    try {
      if (reconnectAttemptsRef.current >= maxReconnectAttempts) {
        toast({
          variant: 'destructive',
          title: 'Connection Failed',
          description: 'Maximum reconnection attempts reached. Please refresh the page.',
        });
        return;
      }

      // Close existing connection if any
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }

      // Use proper URL construction
      const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsUrl = `${wsProtocol}//${window.location.host}/ws`;
      
      ws.current = new WebSocket(wsUrl);
      
      // Add connection timeout
      const connectionTimeout = setTimeout(() => {
        if (ws.current?.readyState !== WebSocket.OPEN) {
          if (ws.current) {
            ws.current.close();
            ws.current = null;
          }
          reconnect();
        }
      }, 5000);

      ws.current.onopen = () => {
        clearTimeout(connectionTimeout);
        console.log('WebSocket Connected');
        reconnectAttemptsRef.current = 0;
        setIsConnected(true);
        
        if (reconnectTimeoutRef.current) {
          clearTimeout(reconnectTimeoutRef.current);
          reconnectTimeoutRef.current = undefined;
        }
        
        toast({
          title: 'Connected',
          description: 'Successfully connected to the server',
        });
      };

      ws.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          
          switch (data.type) {
            case 'connected':
              console.log('WebSocket connection confirmed:', data.message);
              break;
            case 'result':
              toast({
                title: 'Algorithm Executed',
                description: `Profit/Loss: $${data.data.profitLoss}, Trades: ${data.data.trades}, Time: ${data.data.executionTime}`,
              });
              break;
            case 'error':
              toast({
                variant: 'destructive',
                title: 'Error',
                description: data.message,
              });
              break;
            case 'connection_ack':
              console.log('Node connection acknowledged:', data.data);
              break;
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        setIsConnected(false);
      };

      ws.current.onclose = (event) => {
        console.log('WebSocket disconnected');
        setIsConnected(false);
        
        if (!event.wasClean) {
          reconnect();
        }
      };

    } catch (error) {
      console.error('WebSocket initialization error:', error);
      setIsConnected(false);
      reconnect();
    }
  }, [toast]);

  const reconnect = useCallback(() => {
    if (reconnectAttemptsRef.current < maxReconnectAttempts) {
      reconnectAttemptsRef.current++;
      const delay = Math.min(1000 * Math.pow(2, reconnectAttemptsRef.current), 10000);
      
      toast({
        variant: 'destructive',
        title: 'Connection Lost',
        description: `Attempting to reconnect (${reconnectAttemptsRef.current}/${maxReconnectAttempts})...`,
      });

      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }

      reconnectTimeoutRef.current = setTimeout(() => {
        console.log('Attempting to reconnect...');
        connect();
      }, delay);
    }
  }, [connect, toast]);

  useEffect(() => {
    connect();

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, [connect]);

  const sendMessage = useCallback((message: any) => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not connected');
      toast({
        variant: 'destructive',
        title: 'Connection Error',
        description: 'Not connected to server. Please wait for reconnection.',
      });
    }
  }, [toast]);

  return { sendMessage, isConnected };
}
