import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { TrendingUp, Search, Trash2 } from 'lucide-react';
import { technicalIndicators } from '../../lib/technical-indicators';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '../ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../ui/popover';

const indicatorParams = {
  'sma': [
    { id: 'period', name: 'Period', type: 'number', default: 14, description: 'Number of periods to calculate SMA' }
  ],
  'ema': [
    { id: 'period', name: 'Period', type: 'number', default: 12, description: 'Number of periods to calculate EMA' }
  ],
  'rsi': [
    { id: 'period', name: 'Period', type: 'number', default: 14, description: 'Number of periods to calculate RSI' }
  ],
  'macd': [
    { id: 'fastPeriod', name: 'Fast Period', type: 'number', default: 12, description: 'Number of periods for fast EMA' },
    { id: 'slowPeriod', name: 'Slow Period', type: 'number', default: 26, description: 'Number of periods for slow EMA' },
    { id: 'signalPeriod', name: 'Signal Period', type: 'number', default: 9, description: 'Number of periods for signal line' }
  ],
  'bb': [
    { id: 'period', name: 'Period', type: 'number', default: 20, description: 'Number of periods for moving average' },
    { id: 'stdDev', name: 'Standard Deviations', type: 'number', default: 2, description: 'Number of standard deviations' }
  ]
};

export function TechnicalIndicatorNode({ 
  data, 
  id 
}: { 
  data: { 
    type: string; 
    indicator?: string;
    params?: Record<string, number>;
  }; 
  id: string 
}) {
  const [open, setOpen] = useState(false);
  const [indicator, setIndicator] = useState(data.indicator || '');
  const [params, setParams] = useState<Record<string, number>>(data.params || {});
  const { setNodes } = useReactFlow();

  const handleSelect = (id: string) => {
    setIndicator(id);
    data.indicator = id;
    const defaultParams = indicatorParams[id as keyof typeof indicatorParams]?.reduce((acc, param) => {
      acc[param.id] = param.default;
      return acc;
    }, {} as Record<string, number>) || {};
    setParams(defaultParams);
    data.params = defaultParams;
    setOpen(false);
  };

  const handleParamChange = (paramId: string, value: string) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      const newParams = { ...params, [paramId]: numValue };
      setParams(newParams);
      data.params = newParams;
    }
  };

  const selectedIndicator = technicalIndicators.find(i => i.id === indicator);
  const indicatorParameters = indicatorParams[indicator as keyof typeof indicatorParams] || [];

  return (
    <Card className="min-w-[250px] p-4 relative">
      <Handle type="target" position={Position.Top} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mb-2">
          <TrendingUp className="h-4 w-4" />
          <span>Technical Indicator</span>
        </div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              className="justify-between"
            >
              {selectedIndicator ? selectedIndicator.name : 'Select indicator...'}
              <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] p-0">
            <Command>
              <CommandInput placeholder="Search indicators..." />
              <CommandList>
                <CommandEmpty>No indicator found.</CommandEmpty>
                <CommandGroup>
                  {technicalIndicators.map((indicator) => (
                    <CommandItem
                      key={indicator.id}
                      onSelect={() => handleSelect(indicator.id)}
                    >
                      <span>{indicator.name}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {selectedIndicator && (
          <>
            <p className="text-sm text-muted-foreground mb-2">
              {selectedIndicator.description}
            </p>
            
            <div className="flex flex-col gap-2 mt-2">
              {indicatorParameters.map((param) => (
                <div key={param.id} className="flex flex-col gap-1">
                  <label className="text-sm text-muted-foreground">
                    {param.name}
                    <span className="text-xs ml-1">({param.description})</span>
                  </label>
                  <Input
                    type="number"
                    value={params[param.id] || param.default}
                    onChange={(e) => handleParamChange(param.id, e.target.value)}
                    className="w-full"
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
}
