import { useCallback, useState, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  Node,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { TradingNode } from './nodes/TradingNode';
import { DataSourceNode } from './nodes/DataSourceNode';
import { TechnicalIndicatorNode } from './nodes/TechnicalIndicatorNode';
import { LLMAgentNode } from './nodes/LLMAgentNode';
import { ConditionNode } from './nodes/ConditionNode';
import { ReplNode } from './nodes/ReplNode';
import { NotificationNode } from './nodes/NotificationNode';
import { BrokerNode } from './nodes/BrokerNode';
import { useWebSocket } from '../lib/websocket';
import * as Sentry from "@sentry/browser";
import { datadogRum } from '@datadog/browser-rum';

const defaultEdgeOptions = {
  style: {
    strokeWidth: 3, // Adjust this value to change edge thickness
  },
};

const nodeTypes = {
  tradingNode: TradingNode,
  datasource: DataSourceNode,
  indicator: TechnicalIndicatorNode,
  'llm-agent': LLMAgentNode,
  condition: ConditionNode,
  repl: ReplNode,
  notification: NotificationNode,
  broker: BrokerNode,
};

const initialNodes: Node[] = [
  {
    id: '1',
    type: 'datasource',
    position: { x: 250, y: 100 },
    data: { 
      type: 'datasource',
      sourceType: 'exchange',
      exchange: 'TSX',
      ticker: 'L.TO'
    }
  },
  {
    id: '2',
    type: 'datasource',
    position: { x: 900, y: 100 },
    data: { 
      type: 'datasource',
      sourceType: 'news',
      publisher: 'bloomberg',
      topic: 'economy'
    }
  },
  {
    id: '3',
    type: 'indicator',
    position: { x: 100, y: 450 },
    data: {
      type: 'indicator',
      indicator: 'sma',
      params: { period: 14 }
    }
  },
  {
    id: '4',
    type: 'indicator',
    position: { x: 400, y: 450 },
    data: {
      type: 'indicator',
      indicator: 'ema',
      params: { period: 12 }
    }
  },
  {
    id: '5',
    type: 'condition',
    position: { x: 250, y: 900 },
    data: {
      type: 'condition',
      statements: 'if simple moving average crosses exponential moving average and we have Positive News.'
    }
  },
  {
    id: '6',
    type: 'notification',
    position: { x: 250, y: 1200 },
    data: {
      type: 'notification',
      notificationType: 'phone',
      phoneNumber: '',
      content: ''
    }
  },
  {
    id: '7',
    type: 'llm-agent',
    position: { x: 900, y: 450 },
    data: {
      type: 'llm-agent',
      model: 'claude',
      prompt: 'Only forward news titles that are talking about Canadian and US Economy. Pass articles in {title, sentiment_score, timestamp} format.'
    }
  },
  {
    id: '8',
    type: 'repl',
    position: { x: 900, y: 700 },
    data: {
      type: 'repl',
      language: 'python',
      code: `df = get_input_df(parse=True)
positive = df.sentiment_score.sum()

if positive:
    print("Positive News")`
    }
  }
];

const initialEdges: Edge[] = [
  { id: 'e1-3', source: '1', target: '3' },
  { id: 'e1-4', source: '1', target: '4' },
  { id: 'e3-5', source: '3', target: '5' },
  { id: 'e4-5', source: '4', target: '5' },
  { id: 'e5-6', source: '5', target: '6' },
  { id: 'e2-7', source: '2', target: '7' },
  { id: 'e7-8', source: '7', target: '8' },
  { id: 'e8-5', source: '8', target: '5' }
];

export default function Canvas() {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { sendMessage, isConnected } = useWebSocket();

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onConnect = useCallback(
    (connection: Connection) => {
      if (!connection.source || !connection.target) return;
      const edge = { ...connection, id: `${connection.source}-${connection.target}` };
      setEdges((eds) => addEdge(edge, eds));
      
      if (isConnected) {
        sendMessage({
          type: 'connection',
          data: edge,
        });
      }
    },
    [setEdges, sendMessage, isConnected]
  );

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');
      if (!type) return;

      const reactFlowBounds = event.currentTarget.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const newNode: Node = {
        id: `${type}-${Date.now()}`,
        type: ['datasource', 'indicator', 'llm-agent', 'condition', 'repl', 'notification', 'broker'].includes(type) ? type : 'tradingNode',
        position,
        data: { type },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [setNodes]
  );

  const onNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
    datadogRum.addAction('node_clicked', {
      nodeId: node.id,
      nodeType: node.type,
      nodeData: node.data,
      timestamp: new Date().toISOString()
    });
  }, []);

  return (
    <div className="h-full w-full">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onDrop={onDrop}
        onDragOver={onDragOver}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        minZoom={0.7}
        // Disable automatic view fitting when nodes are added/removed
        fitView={false}
        onNodeClick={onNodeClick}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}
