import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { Database, Search, Trash2 } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';

const sourceTypes = [
  { id: 'exchange', name: 'Select Exchange' },
  { id: 'news', name: 'Select News Source' },
  { id: 'file', name: 'Upload Excel File' }
];

const exchanges = [
  { id: 'NASDAQ', name: 'NASDAQ' },
  { id: 'NYSE', name: 'NYSE' },
  { id: 'TSX', name: 'TSX' },
  { id: 'NEO', name: 'NEO' },
];

const publishers = [
  { id: 'reuters', name: 'Reuters' },
  { id: 'bloomberg', name: 'Bloomberg' },
  { id: 'wsj', name: 'Wall Street Journal' },
  { id: 'ft', name: 'Financial Times' },
];

const topics = [
  { id: 'markets', name: 'Markets' },
  { id: 'economy', name: 'Economy' },
  { id: 'companies', name: 'Companies' },
  { id: 'commodities', name: 'Commodities' },
];

export function DataSourceNode({ 
  data, 
  id 
}: { 
  data: { 
    type: string;
    sourceType?: string;
    exchange?: string;
    ticker?: string;
    publisher?: string;
    topic?: string;
    fileName?: string;
    tickers?: string[];
    exchanges?: string[];
  }; 
  id: string 
}) {
  const [sourceType, setSourceType] = useState(data.sourceType || '');
  const [exchange, setExchange] = useState(data.exchange || '');
  const [ticker, setTicker] = useState(data.ticker || '');
  const [publisher, setPublisher] = useState(data.publisher || '');
  const [topic, setTopic] = useState(data.topic || '');
  const { setNodes } = useReactFlow();

  const handleSourceTypeChange = (value: string) => {
    setSourceType(value);
    data.sourceType = value;
    // Reset other values when source type changes
    setExchange('');
    setTicker('');
    setPublisher('');
    setTopic('');
  };

  const handleExchangeChange = (value: string) => {
    setExchange(value);
    data.exchange = value;
  };

  const handlePublisherChange = (value: string) => {
    setPublisher(value);
    data.publisher = value;
  };

  const handleTopicChange = (value: string) => {
    setTopic(value);
    data.topic = value;
  };

  const handleTickerSet = () => {
    data.ticker = ticker;
  };

  return (
    <Card className="min-w-[300px] p-4 relative">
      <Handle type="source" position={Position.Bottom} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mb-2">
          <Database className="h-4 w-4" />
          <span>Data Source</span>
        </div>

        <Select value={sourceType} onValueChange={handleSourceTypeChange}>
          <SelectTrigger>
            <SelectValue placeholder="Select source type" />
          </SelectTrigger>
          <SelectContent>
            {sourceTypes.map((type) => (
              <SelectItem key={type.id} value={type.id}>
                {type.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {sourceType === 'exchange' && (
          <>
            <Select value={exchange} onValueChange={handleExchangeChange}>
              <SelectTrigger>
                <SelectValue placeholder="Select exchange" />
              </SelectTrigger>
              <SelectContent>
                {exchanges.map((ex) => (
                  <SelectItem key={ex.id} value={ex.id}>
                    {ex.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <div className="flex gap-2">
              <Input
                value={ticker}
                onChange={(e) => setTicker(e.target.value.toUpperCase())}
                placeholder="Enter ticker..."
                className="flex-1"
              />
              <Button size="sm" onClick={handleTickerSet}>
                Set
              </Button>
            </div>
          </>
        )}

        {sourceType === 'news' && (
          <>
            <Select value={publisher} onValueChange={handlePublisherChange}>
              <SelectTrigger>
                <SelectValue placeholder="Select publisher" />
              </SelectTrigger>
              <SelectContent>
                {publishers.map((pub) => (
                  <SelectItem key={pub.id} value={pub.id}>
                    {pub.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Select value={topic} onValueChange={handleTopicChange}>
              <SelectTrigger>
                <SelectValue placeholder="Select topic" />
              </SelectTrigger>
              <SelectContent>
                {topics.map((t) => (
                  <SelectItem key={t.id} value={t.id}>
                    {t.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </>
        )}

        {sourceType === 'file' && (
          <div className="flex flex-col gap-2">
            <Input
              type="file"
              accept=".xlsx,.xls,.csv"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  // Store file info in data
                  data.fileName = file.name;
                  
                  // Create FormData and upload
                  const formData = new FormData();
                  formData.append('file', file);
                  
                  // TODO: Implement file upload endpoint
                  fetch('/api/upload-tickers', {
                    method: 'POST',
                    body: formData
                  })
                  .then(response => response.json())
                  .then(result => {
                    // Store uploaded tickers data
                    data.tickers = result.tickers;
                    data.exchanges = result.exchanges;
                  })
                  .catch(error => {
                    console.error('Error uploading file:', error);
                  });
                }
              }}
              className="cursor-pointer"
            />
            <p className="text-sm text-muted-foreground">
              Upload Excel file with columns: Ticker, Exchange
            </p>
          </div>
        )}
      </div>
    </Card>
  );
}
