import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Textarea } from '../ui/textarea';
import { Button } from '../ui/button';
import { Code, Search, Trash2 } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '../ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../ui/popover';

const languages = [
  { id: 'python', name: 'Python', description: 'Python programming language' },
  { id: 'clojure', name: 'Clojure', description: 'Clojure programming language' },
  { id: 'r', name: 'R', description: 'R programming language' },
];

export function ReplNode({ data, id }: { data: { type: string; language?: string; code?: string }; id: string }) {
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState(data.language || '');
  const [code, setCode] = useState(data.code || '');
  const { setNodes } = useReactFlow();

  const handleSelect = (id: string) => {
    setLanguage(id);
    data.language = id;
    setOpen(false);
  };

  const handleCodeChange = (value: string) => {
    setCode(value);
    data.code = value;
  };

  const selectedLanguage = languages.find(l => l.id === language);

  return (
    <Card className="min-w-[300px] p-4 relative">
      <Handle type="target" position={Position.Top} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mb-2">
          <Code className="h-4 w-4" />
          <span>Mini Program</span>
        </div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" role="combobox" className="justify-between">
              {selectedLanguage ? selectedLanguage.name : 'Select language...'}
              <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] p-0">
            <Command>
              <CommandInput placeholder="Search languages..." />
              <CommandList>
                <CommandEmpty>No language found.</CommandEmpty>
                <CommandGroup>
                  {languages.map((lang) => (
                    <CommandItem key={lang.id} onSelect={() => handleSelect(lang.id)}>
                      <span>{lang.name}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {selectedLanguage && (
          <p className="text-sm text-muted-foreground">
            {selectedLanguage.description}
          </p>
        )}
        <Textarea
          value={code}
          onChange={(e) => handleCodeChange(e.target.value)}
          placeholder="Enter your code..."
          className="min-h-[150px] mt-2 font-mono"
        />
      </div>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
}
