import { useCallback } from "react";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { tradingComponents } from "../lib/trading-components.tsx";
import { useReactFlow } from 'reactflow';
import { useWebSocket } from "../lib/websocket";
import { useToast } from "@/hooks/use-toast";
import { GripVertical, ArrowRight } from 'lucide-react';

export default function Sidebar() {
  const { getNodes, getEdges, setNodes } = useReactFlow();
  const { sendMessage } = useWebSocket();
  const { toast } = useToast();

  const onDragStart = useCallback((event: React.DragEvent, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  }, []);

  const executeAlgorithm = useCallback(() => {
    const nodes = getNodes();
    const edges = getEdges();
    
    if (nodes.length === 0) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please add at least one component to the canvas",
      });
      return;
    }

    // Send to backend via WebSocket
    sendMessage({
      type: 'execute',
      data: { nodes, edges }
    });
  }, [getNodes, getEdges, sendMessage, toast]);

  return (
    <div className="w-64 border-r bg-muted p-4">
      <h1 className="mb-2 text-lg font-bold text-red-500">WIP: PROTOTYPE</h1>
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-lg font-bold">Components</h2>
      </div>
      <div className="flex items-center gap-2 mb-2 text-sm text-muted-foreground">
        <ArrowRight className="h-4 w-4" />
        <span>Drag or click elements to add them to canvas</span>
      </div>
      <div className="space-y-2">
        {tradingComponents.map((component) => (
          <Card
            key={component.type}
            className="cursor-pointer p-3"
            draggable
            onDragStart={(e) => onDragStart(e, component.type)}
            onClick={() => {
              const position = { x: 100, y: 100 };
              const newNode = {
                id: `${component.type}-${Date.now()}`,
                type: ['datasource', 'indicator', 'llm-agent', 'condition', 'repl', 'notification', 'broker'].includes(component.type) ? component.type : 'tradingNode',
                position,
                data: { type: component.type },
              };
              setNodes((nds) => nds.concat(newNode));
            }}
          >
            <div className="flex items-center gap-2">
              {component.icon}
              <span>{component.label}</span>
            </div>
          </Card>
        ))}
      </div>
      <div className="mt-4 space-y-2">
        <div className="relative">
          <Button 
            className="w-full opacity-50 cursor-not-allowed"
            onClick={executeAlgorithm}
            disabled
          >
            Execute Algorithm
            <span className="text-xs text-red-500 absolute top-0 right-2">
              Coming soon
            </span>
          </Button>
        </div>
        <Button
          className="w-full"
          variant="outline"
          onClick={() => window.open('https://i6ndhz7oa1j.typeform.com/to/mvRyOrzw', '_blank')}
          style={{ color: 'rgb(244 63 94)', borderColor: 'rgb(244 63 94)' }}
        >
          Follow For Updates
        </Button>
      </div>
    </div>
  );
}
