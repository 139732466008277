import { ReactFlowProvider } from 'reactflow';
import Canvas from '../components/Canvas';
import Sidebar from '../components/Sidebar';
import { ErrorBoundary } from '../components/ErrorBoundary';

export default function Home() {
  return (
    <div className="flex h-screen bg-background text-foreground">
      <ReactFlowProvider>
        <ErrorBoundary>
          <Sidebar />
        </ErrorBoundary>
        <div className="flex-1">
          <ErrorBoundary>
            <Canvas />
          </ErrorBoundary>
        </div>
      </ReactFlowProvider>
    </div>
  );
}
