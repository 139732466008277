import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Button } from '../ui/button';
import { Trash2 } from 'lucide-react';
import { tradingComponents } from '../../lib/trading-components.tsx';

export function TradingNode({ data, id }: { data: { type: string }; id: string }) {
  const component = tradingComponents.find((c) => c.type === data.type);
  const { setNodes } = useReactFlow();

  return (
    <Card className="min-w-[150px] p-4 relative">
      <Handle type="target" position={Position.Top} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex items-center gap-2">
        {component?.icon}
        <span>{component?.label}</span>
      </div>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
}
