import { useState } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { Card } from '../ui/card';
import { Input } from '../ui/input';
import { Textarea } from '../ui/textarea';
import { Button } from '../ui/button';
import { Bot, Search, Trash2 } from 'lucide-react';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';

const llmAgents = [
  { id: 'gpt4', name: 'GPT-4', description: 'Advanced language model for complex reasoning' },
  { id: 'claude', name: 'Claude', description: 'Anthropic AI assistant' },
  { id: 'custom', name: 'Custom Agent', description: 'Your own custom LLM agent' },
];

export function LLMAgentNode({ data, id }: { data: { type: string; agent?: string; prompt?: string }; id: string }) {
  const [open, setOpen] = useState(false);
  const [agent, setAgent] = useState(data.agent || '');
  const [prompt, setPrompt] = useState(data.prompt || '');
  const { setNodes } = useReactFlow();

  const handleSelect = (id: string) => {
    setAgent(id);
    data.agent = id;
    setOpen(false);
  };

  const handlePromptChange = (value: string) => {
    setPrompt(value);
    data.prompt = value;
  };

  const selectedAgent = llmAgents.find(a => a.id === agent);

  return (
    <Card className="min-w-[300px] p-4 relative">
      <Handle type="target" position={Position.Top} />
      <div className="absolute top-2 right-2">
        <Button
          size="sm"
          variant="ghost"
          className="h-6 w-6 p-0 hover:bg-destructive hover:text-destructive-foreground"
          onClick={() => {
            setNodes((nodes) => nodes.filter((n) => n.id !== id));
          }}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 mb-2">
          <Bot className="h-4 w-4" />
          <span>LLM Agent</span>
        </div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button variant="outline" role="combobox" className="justify-between">
              {selectedAgent ? selectedAgent.name : 'Select agent...'}
              <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] p-0">
            <Command>
              <CommandInput placeholder="Search agents..." />
              <CommandList>
                <CommandEmpty>No agent found.</CommandEmpty>
                <CommandGroup>
                  {llmAgents.map((agent) => (
                    <CommandItem key={agent.id} onSelect={() => handleSelect(agent.id)}>
                      <span>{agent.name}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {selectedAgent && (
          <p className="text-sm text-muted-foreground">
            {selectedAgent.description}
          </p>
        )}
        <Textarea
          value={prompt}
          onChange={(e) => handlePromptChange(e.target.value)}
          placeholder="Enter prompt..."
          className="min-h-[100px] mt-2"
        />
      </div>
      <Handle type="source" position={Position.Bottom} />
    </Card>
  );
}
