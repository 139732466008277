import { useIsMobile } from "../hooks/use-mobile";
import { AlertCircle } from "lucide-react";
import { Alert, AlertTitle } from "./ui/alert";

export function MobileWarning() {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-background/80 backdrop-blur-sm">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-4">
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Mobile Access Restricted</AlertTitle>
        </Alert>
        <div className="text-center">
          <h2 className="text-xl font-bold mb-4">Desktop Only Application</h2>
          <p className="text-muted-foreground mb-4">
            This application requires drag-and-drop functionality and is not usable on mobile devices. Please access it from a desktop computer.
          </p>
          <p className="text-sm text-destructive font-semibold">
            The application will not function correctly on mobile devices.
          </p>
        </div>
      </div>
    </div>
  );
}